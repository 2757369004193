import React from 'react';
import { defineMessages } from 'react-intl';
import { KyruusFormattedMessage } from '@kyruus/intl';
import PhoneNumberLink from './phone-number-link';
import { PleaseCallContainer } from './styles';

const messages = defineMessages({
  pleaseCallTitle: {
    id: 'pleasecall.title',
    description: 'First paragraph to render in the PleaseCallMessage.',
    defaultMessage: "Can't find what you need?"
  },
  pleaseCallCTA: {
    id: 'pleasecall.cta',
    description:
      'Second paragraph to render in the PleaseCallMessage, with CTA to call the office.',
    defaultMessage: 'Call {providerPhone} to book an appointment.'
  }
});

const PleaseCallMessage = ({ providerPhone }) => {
  return (
    <PleaseCallContainer data-testid="PleaseCallContainer">
      <div>
        <KyruusFormattedMessage {...messages.pleaseCallTitle} />
      </div>
      <div>
        <KyruusFormattedMessage
          {...messages.pleaseCallCTA}
          values={{
            providerPhone: <PhoneNumberLink phone={providerPhone} />
          }}
        />
      </div>
    </PleaseCallContainer>
  );
};

export default PleaseCallMessage;
